<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Проекты по транскибации (Speech-To-Text)</h1>
        </div>
      </div>
    </div>

    <div class="container">


      <b-dropdown class="btn-group mr-2 mt-2" variant="primary" text="Создать проект">
        <b-dropdown-item @click="createProject('sberspeech')">SberSpeech</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="createProject('whisper')">Whisper</b-dropdown-item>
      </b-dropdown>

      <h2> Мои проекты </h2>

      <div class="row m-12 justify-content-center align-items-center">


        <div class="col-11">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center" style="width:100%;">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Название</th>
                  <th scope="col">Открыть</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(project, ix) in projects">

                  <td>{{ ix + 1 }}</td>

                  <td>

                    <div v-if="!editMode & ix_edit === ix" class="form-group">
                      <div class="position-relative">
                        <input name="name" type="text" class="form-control " placeholder="Название шаблона :"
                          v-model="projects[ix].project_name" />
                      </div>
                      <a class="btn btn-icon btn-outline-success" @click="saveItem(ix)"><check-icon
                          class="fea icon-sm"></check-icon></a>
                      <a class="btn btn-icon btn-outline-danger" @click="deleteItem(ix)"><trash-icon
                          class="fea icon-sm"></trash-icon></a>
                    </div>
                    <div class="position-relative" v-else>{{ project.project_name }}
                      <a class="btn btn-icon btn-outline-light" @click="editModeChange(ix)"><edit-icon
                          class="fea icon-sm"></edit-icon></a>
                    </div>



                  </td>
                  <td>
                    <a class="btn btn-icon btn-pills btn-outline-primary"
                      @click="goToProject(project.project_hash)"><arrow-right-icon /></a>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>





      </div>

    </div>

  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon, ArrowRightIcon, EditIcon, SaveIcon, DeleteIcon, TrashIcon, CheckIcon } from "vue-feather-icons";


export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon, ArrowRightIcon, EditIcon, SaveIcon, DeleteIcon, TrashIcon, CheckIcon,
  },
  name: "SoundFile2Text",
  data() {
    return {
      projects: [],
      editMode: true,
      input_api: { user_id: 1, project_hash: '', project_name: '' },
      ix_edit: -1,
    }
  },



  mounted() {
    this.getProjects();
  },
  methods: {

    goToProject(project_hash) {
      window.open('' + this.$hostnamefront + '/speech2text-history/' + project_hash, '_blanc');
    },
    editModeChange(ix) {
      this.ix_edit = ix
      this.editMode = !this.editMode
    },
    saveItem(ix) {
      this.input_api.project_name = this.projects[ix].project_name;
      this.input_api.project_hash = this.projects[ix].project_hash;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisper/save-project-name',
        data: this.input_api,
      }).then((res) => {

        this.projects = [];
        this.getProjects();

      });
    },

    deleteItem(ix) {
      this.input_api.project_name = this.projects[ix].project_name;
      this.input_api.project_hash = this.projects[ix].project_hash;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisper/delete-project',
        data: this.input_api,
      }).then((res) => {
        this.projects = [];
        this.getProjects();
      });
    },

    createProject(x) {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$CryptoJS.MD5(store.state.user_id + new Date()).toString();
      this.input_api.project_name = 'Проект ' + this.projects.length;
      this.input_api.transcriber_type = x;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisper/write-meta',
        data: this.input_api,
      }).then((res) => {
        this.$router.push({ path: '/speech2text/' + this.input_api.project_hash });
      });

    },

    getProjects() {
      this.ix_edit = -1;
      this.editMode = false;
      this.input_api.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisper/get-projects',
        data: this.input_api,
      }).then((res) => {
        this.projects = [];
        for (let i = 0; i < res.data.projects.length; i++) {
          this.projects.push({
            'project_name': res.data.projects[i].project_name,
            'project_hash': res.data.projects[i].project_hash
          })
        };
      });

    },

  }
}
</script>

<style scoped></style>